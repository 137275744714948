.amap-logo {
  display: none !important;
}

.amap-copyright {
  bottom: -100px;
  display: none !important;
}

.HeadCenter {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  //color: #FFF;

  margin: 0 auto;
  //width: 100px;
  //background-color: rgba(255, 255, 255, 0.12);
  display: flex;
  justify-content: center;
}

.HeadCenter .title {
  padding: 2px 20px;
  color: #FFF;
  font-size: 35px;
  background-color: rgba(255, 255, 255, 0.12);
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  //word-spacing: 10px;
}

.Head {
  position: fixed;
  top: 0;
  right: 0;
  color: #FFF;
  padding: 6px 10px;
  background-color: rgba(255, 255, 255, 0.12);
  //background: 0px 0px repeat-x, linear-gradient(to bottom, #68dfa9, #5bc2bf 10px, #f5f6f8 20px);
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  border-bottom-left-radius: 4px;
  //min-width: 380px;
}

.Head .title {}

.Head .kong {
  flex: 1;
}

.Head .time {}

.Left {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  //width: 80px;
  color: #FFF;
  background-color: rgba(255, 255, 255, 0.12);
  //background: 0px 0px repeat-x, linear-gradient(to bottom, #68dfa9, #5bc2bf 10px, #f5f6f8 20px);
  display: flex;
  justify-content: start;
  align-items: center;
  flex-direction: column;
}

.Left .menu {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px 10px;
  position: relative;
  cursor: pointer;
}

.Left .menu:hover {
  background-color: rgba(255, 255, 255, 0.2);
  color: #FFF;
}

.Left .menu.active {
  background-color: rgba(0, 0, 0, 0.55)
}

.Left .menu .iconDiv {
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Left .menu .iconDiv .icon {
  stroke: #FFF;
  fill: #FFF;
  width: 30px;
  height: 30px;
}

// .Left .menu:hover .iconDiv .icon {
//   stroke: black;
//   fill: black;
// }

@keyframes myfirst_text {
  from {
    left: 160px;
    opacity: 0;
  }

  to {
    left: 100px;
    opacity: 1;
  }
}

.Left .menu .text {
  display: none;
  word-break: keep-all;
  font-size: 16px;
  position: absolute;
  padding: 2px 5px;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  background-color: rgba(0, 121, 181, 0.785);
  left: 100px;
  opacity: 1;
  animation-duration: 0.15s;
  animation-timing-function: ease-in-out;
  animation-delay: 0s;
  animation-iteration-count: 1;
  //animation-direction: alternate;
  animation-play-state: running;
}

.Left .menu:hover .text {
  display: block;
  animation-name: myfirst_text;
}

.Left .menu.active:hover .text {
  display: none;
}


.Left .menu .text::before {
  position: absolute;
  content: '';
  left: -26px;
  top: 0;
  width: 0;
  height: 0;
  border: 13px solid transparent;
  border-right: 13px solid rgba(0, 121, 181, 0.785);
}

.Left .menu .count {
  font-size: 10px;
  color: #FFF;
}

// .Left .menu:hover .count {
//   color: black
// }

.Left .menu .line {
  margin-top: 4px;
  background-color: white;
  width: 100%;
  border-radius: 100px;
  overflow: hidden;
}

// .Left .menu:hover .line {
//   background-color: gray;
// }

.Left .menu .line .line_right {
  background-color: rgb(55, 239, 55);
  width: 50%;
  height: 3px;
  position: relative;
}

.Left .menu .loading {
  height: 21px;
  font-size: 12px;
}

.Left .menu .err {
  height: 21px;
  font-size: 12px;
  color: red;
}

@keyframes myfirst {
  from {
    left: -15px;
  }

  to {
    left: 100%
  }
}

.Left .menu .line .line_right::after {
  content: '';
  //background-color: red;
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0), rgb(255, 255, 255) 50%, rgba(255, 255, 255, 0));
  position: absolute;
  left: -15px;
  top: 0;
  width: 15px;
  height: 3px;
  animation-name: var(--keyframes); //myfirst;
  animation-duration: 1.5s;
  animation-timing-function: linear;
  animation-delay: 0s;
  animation-iteration-count: 1;
  //animation-direction: alternate;
  animation-play-state: running;
}

.Left .menu .content {
  display: none;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.55);
  width: 400px;
  left: 70px;
  top: 0;
  bottom: 0;
  padding: 20px;
  box-sizing: border-box;
}

.Left .menu.active .content {
  display: block;
  cursor: default;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.Left .menu.active .content .content_title {
  font-size: 16px;
  padding-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Left .menu.active .content .content_title.mt20 {
  margin-top: 50px;
}

.Left .menu.active .content .content_cont {
  flex: 1;
  overflow-y: auto;
}

.Left .menu.active .content .content_cont::-webkit-scrollbar {
  display: none;
  /* Chrome Safari */
}

.Left .menu.active .content .table_tr {
  font-size: 14px;
  display: flex;
  //padding: 6px;
  margin-top: 6px;
}

.Left .menu.active .content .table_tr.t {
  background-color: rgba(0, 121, 181, 0.785);
  margin-top: 0;
}

.Left .menu.active .content .table_tr.active {
  background-color: rgba(0, 121, 181, 0.5);
}

.Left .menu.active .content .table_tr.active:hover {
  background-color: rgba(0, 121, 181, 0.5);
}

.Left .menu.active .content .table_tr:hover {
  background-color: rgba(0, 121, 181, 0.3);
}

.Left .menu.active .content .table_tr.t:hover {
  background-color: rgba(0, 121, 181, 0.785);
}

.Left .menu.active .content .table_tr .table_th {
  font-weight: bold;
  padding: 5px 10px;
}

.Left .menu.active .content .table_tr .table_th .status {
  display: flex;
  align-items: center;
}

.Left .menu.active .content .table_tr .table_th.f {
  flex: 1;
}

.Left .menu.active .content .table_tr .table_th.r {
  text-align: right;
}

.Left .menu.active .content .table_tr .table_td {
  padding: 5px 10px;
}

.Left .menu.active .content .table_tr .table_td.first {
  width: 45px
}

.Left .menu.active .content .table_tr .table_td.f {
  flex: 1;
}

.Left .menu.active .content .table_tr .table_td.r {
  text-align: right;
}

.Left .online {
  color: aqua;
}

.Left .onlineIcon {
  width: 15px;
  height: 15px;
  fill: aqua;
}

.Left .offline {
  color: #ff000099;
}

.Left .offlineIcon {
  width: 15px;
  height: 15px;
  fill: #ff000099;
}


.d_view {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.d_view .iframe_p {
  align-self: stretch;
  min-height: 214px;
  display: flex;
  position: relative;
  background-color: rgba(0, 0, 0, 0.55);
  border: 1px solid aqua;
  margin-top: -1px;
}

.d_view .iframe_p.cq {
  display: block;
  flex: 1;
  overflow-y: auto;
  align-items: start;
}

.d_view .iframe_p.cq::-webkit-scrollbar {
  display: none;
  /* Chrome Safari */
}

.d_view .iframe_p .offline {
  font-size: 30px;
  opacity: 0.7;
  flex: 1;
  text-align: center;
  align-self: center;
}

.d_view .iframe_p .msg {
  font-size: 28px;
  opacity: 1;
  flex: 1;
  text-align: center;
  align-self: center;
}

.d_view .iframe_p .msg.red {
  font-size: 28px;
  opacity: 1;
  flex: 1;
  text-align: center;
  align-self: center;
  color: red;
}


.d_view .iframe_p_dis {
  align-self: stretch;
  height: 214px;
  display: flex;
  position: relative;

  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  font-size: 25px;
  color: rgba(255, 255, 255, 0.514);
}

.d_view .iframe_p iframe {
  border: 1px solid aqua;
  box-sizing: border-box;
  flex: 1;
}

.d_view .iframe_p video {
  //border: 1px solid aqua;
  box-sizing: border-box;
  flex: 1;
  align-items: stretch;
}

.d_view .iframe_p .iconDiv {
  cursor: pointer;
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
}

.d_view .iframe_p:hover .iconDiv {
  opacity: 1;
}

.d_view .iframe_p .iconDiv .icon {
  fill: #FFF;
  width: 100px;
  height: 100px;
}

.d_view .title {
  font-size: 14px;
  margin-top: 10px;
}

.d_view .detail {
  font-size: 14px;
  width: 100%;
  margin-top: 10px;
}

.d_view .detail table {
  border-spacing: 0;
  border-collapse: collapse;
  border: none;
  width: 100%;
}

.d_view .detail table td {
  border: 1px solid aqua;
  background: #004467;
  padding: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 98px;
}

.d_view .detail table td.t {
  background: rgba(0, 68, 103, 0.4);
  text-align: right;
}



.Location {
  position: fixed;
  width: 50px;
  height: 50px;
  right: 15px;
  bottom: 15px;
  fill: #FFF;
  opacity: 0.5;
  cursor: pointer;
}

.Location:hover {
  opacity: 1;
}

.Right {
  position: fixed;
  right: 0;
  top: 50px;
  width: 380px;
  color: #FFF;
  background-color: rgba(0, 0, 0, 0.55);
  padding: 10px;
  cursor: default;
}

.Right.cq {
  bottom: 80px;
  display: flex;
  flex-direction: column;
}

.Right.cq .d_view {
  flex: 1
}

.Right .content_title {
  font-size: 16px;
  padding-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.Right .online {
  color: aqua;
}

.Right .onlineIcon {
  width: 15px;
  height: 15px;
  fill: aqua;
}

.Right .offline {
  color: #ff000099;
}

.Right .offlineIcon {
  width: 15px;
  height: 15px;
  fill: #ff000099;
}

.Right .d_channel {
  display: flex;
}

.Right .d_channel .d_channel_item {
  border: 1px solid #FFF;
  padding: 5px 10px;
  margin-right: 2px;
  opacity: 0.4;
}

.Right .d_channel .d_channel_item.choose {
  opacity: 1;

  border: 1px solid aqua;
}

.BigIframeBj {
  position: fixed;
  margin: auto;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.85);
  z-index: 999;
  cursor: default;
}

.BigIframe {
  position: fixed;
  margin: auto;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 1202px;
  height: 702px;
  //height: calc(100vw/4*3 * 0.5);
  z-index: 1000;
  display: flex;
}

.BigIframe .Content {
  position: relative;
  width: 100%;
  //height: 100%; 
  display: flex;
  align-items: stretch;
}


.BigIframe .Content iframe {
  border: 1px solid aqua;
  width: 100%;
  height: 100%;
}

.BigIframe .Content .close {
  width: 40px;
  height: 40px;
  fill: #FFF;
  opacity: 0.5;
  position: absolute;
  top: -40px;
  right: -40px;
  cursor: pointer;
}

.BigIframe .Content .close:hover {
  opacity: 1;

}

.BigIframe .Content .Content2 {
  position: relative;
  flex: 1;
  border: 1px solid aqua;
}

.BigIframe .Content .Content2 .iconDiv2 {
  width: 50px;
  height: 50px;
  color: #FFF;
  position: absolute;
  right: 10px;
  bottom: 10px;

}

.BigIframe .Content .Content2 .iconDiv2 .icon {
  fill: #FFF
}


.Meteorological_qx {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
}

.Meteorological_qx .View {
  width: 50%;
  display: flex;
  padding: 20px 0;
  box-sizing: border-box;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  //height: 50%;
}


.Meteorological_qx .View .Item {
  font-size: 30px;
}

.Meteorological_qx .View .Item2 {
  opacity: 0.6;
  font-size: 14px;
}

.Meteorological_tr {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex: 1;
}


.Meteorological_tr .View2 {
  flex: 1;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  height: 100%;
}


.Meteorological_tr .View2 .Item {
  font-size: 30px;
}

.Meteorological_tr .View2 .Item2 {
  opacity: 0.6;
  font-size: 14px;
}


@keyframes mapDian_animation {
  0% {
    margin-top: 0px;
    transform: scale(1);
  }

  25% {
    margin-top: -10px;
    transform: scale(1.5);
  }

  50% {
    margin-top: -17px;
    transform: scale(1.7);
  }

  75% {
    margin-top: -10px;
    transform: scale(1.5);
  }

  100% {
    margin-top: 0px;
    transform: scale(1);
  }
}

.mapDian {
  animation: mapDian_animation 0.7s infinite;
}